import axios from 'axios'
import Layout from 'components/Layout'
import { Col, Row, Section } from 'components/grid'
import React, { useEffect, useState } from 'react'
import { BarChart, LineChart, ReferenceLine, Line, Label, AreaChart, Area, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { FiscalYearLeadCount, transformLeadData } from 'utils/transformLeadData'

const auth_token = process.env.GATSBY_EXTERNAL_AUTH_TOKEN as string
const axiosConfig = { headers: { Authorization: auth_token } }

export default function LeadsPage() {
	const [leads, setLeads] = useState<FiscalYearLeadCount[]>([])

	const difference = calculateDifference(leads)

	useEffect(() => {
		async function getLeads() {
			try {
				let leads: FiscalYearLeadCount[] = []
				const { data: twentyOne } = await axios.get<FiscalYearLeadCount>('/api/leads/fiscal-year/2021', axiosConfig)
				const { data: twentyTwo } = await axios.get<FiscalYearLeadCount>('/api/leads/fiscal-year/2022', axiosConfig)
				const { data: twentyThree } = await axios.get<FiscalYearLeadCount>('/api/leads/fiscal-year/2023', axiosConfig)
				const { data: twentyFour } = await axios.get<FiscalYearLeadCount>('/api/leads/fiscal-year/2024', axiosConfig)
				leads.push(twentyOne, twentyTwo, twentyThree, twentyFour)
				const transformedLeads = transformLeadData(leads)
				setLeads(transformedLeads)
			} catch (error: any) {
				console.log(error.message)
			}
		}
		getLeads()
	}, [])

	return (
		<Layout>
			<Section margin="mt-20">
				<Row middle>
					<Col width="w-full xl:w-1/2">
						<LineChart
							width={640}
							height={400}
							data={leads}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<YAxis />
							<Tooltip />
							<Legend />
							<Line type="monotone" strokeWidth={3} dataKey="FY21" stroke="#003f5c" />
							<Line type="monotone" strokeWidth={3} dataKey="FY22" stroke="#7a5195" />
							<Line type="monotone" strokeWidth={3} dataKey="FY23" stroke="#ef5675" />
							<Line type="monotone" strokeWidth={3} dataKey="FY24" stroke="#ffa600" />
						</LineChart>
						{/* <BarChart
							width={640}
							height={400}
							data={leads}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<YAxis />
							<Tooltip />
							<Legend />
							<Bar dataKey="FY21" fill="#003f5c" />
							<Bar dataKey="FY22" fill="#7a5195" />
							<Bar dataKey="FY23" fill="#ef5675" />
							<Bar dataKey="FY24" fill="#ffa600" />
						</BarChart> */}
					</Col>
					<Col width="w-full xl:w-1/2">
						<AreaChart
							width={640}
							height={200}
							data={leads}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
							syncId={1}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<Tooltip />
							<Legend />
							<Area type="monotone" dataKey="FY21" stroke="#003f5c" fill="#003f5c" fillOpacity={0.4} />
						</AreaChart>
						<AreaChart
							width={640}
							height={200}
							data={leads}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
							syncId={1}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<Tooltip />
							<Legend />

							<Area type="monotone" dataKey="FY22" stroke="#7a5195" fill="#7a5195" fillOpacity={0.4} />
						</AreaChart>
						<AreaChart
							width={640}
							height={200}
							data={leads}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
							syncId={1}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<Tooltip />
							<Legend />

							<Area type="monotone" dataKey="FY23" stroke="#ef5675" fill="#ef5675" fillOpacity={0.4} />
						</AreaChart>
						<AreaChart
							width={640}
							height={200}
							data={leads}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5
							}}
							syncId={1}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />
							<Tooltip />
							<Legend />

							<Area type="monotone" dataKey="FY24" stroke="#ffa600" fill="#ffa600" fillOpacity={0.4} />
						</AreaChart>
					</Col>
				</Row>
				{/* <Row className="mt-40">
					<Col width="w-1/2">
						<BarChart
							width={640}
							height={400}
							data={leads}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label" />

							<Tooltip />
							<Legend />
							<Bar dataKey="FY21" stackId="a" fill="#003f5c" />
							<Bar dataKey="FY22" stackId="a" fill="#7a5195" />
							<Bar dataKey="FY23" stackId="a" fill="#ef5675" />
							<Bar dataKey="FY24" stackId="a" fill="#ffa600" />
						</BarChart>
					</Col>
					<Col width="w-1/2" className="mb-10">
						<BarChart
							width={640}
							height={400}
							data={difference}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 20
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="label">
								<Label value="FY22-FY23 Difference" offset={-10} position="insideBottom" />
							</XAxis>
							<YAxis />
							<Tooltip />
							<ReferenceLine y={0} stroke="#000" />
							<Bar dataKey="difference" fill="#ef5675" />
						</BarChart>
					</Col>
				</Row> */}
			</Section>
		</Layout>
	)
}

function calculateDifference(data: FiscalYearLeadCount[]) {
	return data.map((item) => {
		return {
			label: item.label,
			difference: item.FY23 - item.FY22
		}
	})
}
